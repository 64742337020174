import { useState } from "react";
import { Toolbar, AppBar, Button, Typography, Container, IconButton, alpha } from "@mui/material";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import WalletPopover from "../WalletPopover";
import { ReactComponent as WalletIcon } from "../../../assets/icons/wallet/wallet.svg";
import { ReactComponent as WalletWhiteIcon } from "../../../assets/icons/wallet/wallet-white.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as UserWhiteIcon } from "../../../assets/icons/user-white.svg";
import { AnimatePresence, motion } from "framer-motion";
import { useWeb3React } from "@web3-react/core";
import { useEagerConnect } from "../../../hooks/useEagerConnect";
import { useInactiveListener } from "../../../hooks/useInactiveListener";
import BurgerToggleIcon from "./BurgerToggleIcon";
import { varFadeIn } from "../../../components/Animate";
import { PAGES } from "../../../constants/pages";
import ChangeNetworkAlert from "../../../components/ChangeNetworkAlert";
import ReactGA from "react-ga4";
import AccountPopover from "../AccountPopover";
import { PATH_EXTERNAL } from "../../../routes/paths";
import { __STAGING__ } from "../../../utils/dev";
import clsx from "clsx";
import ClickAwayListener from "@mui/base/ClickAwayListener";

const CDN_ASSETS = {
  LOGO: __STAGING__
    ? "https://storage.delysium.com/ui/delysium_logo_mobile_staging.png"
    : "https://storage.delysium.com/ui/delysium_logo.png"
};

const useStyles = makeStyles((theme) => {
  return {
    navLink: {
      fontSize: "16px",
      position: "relative",
      cursor: "pointer",
      padding: "0 25px",
      marginBottom: "16px",
      marginTop: "20px",
      marginLeft: "56px",
      lineHeight: "20px",
      color: theme.palette.text.primary,
      transition: "all .2s ease-in-out",
      WebkitTapHighlightColor: "transparent",
      outline: "0",
      textTransform: "uppercase",
      textDecoration: "none"
    },

    activeNavLink: {
      "&::before": {
        content: "''",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "15px",
        borderLeft: `2px solid ${theme.palette.text.primary}`,
        height: "17px",
        width: "1px"
      },
      "& div": {
        position: "relative"
      }
    },
    connectButton: {
      "--aug-bl": "6px"
    },
    iconButton: {
      transition: "none",
      borderRadius: 0,
      marginLeft: "0.5rem",
      padding: "3px",
      height: "26px",
      width: "26px",
      "--aug-border-bg": theme.palette.text.primary,
      "--aug-border-all": "1px",
      "--aug-br": "6px",
      "--aug-tl": "6px"
    },
    iconButtonColored: {
      "--aug-border-bg": theme.palette.primary.main
    }
  };
});

const appBarSX = {
  position: "relative",
  width: "initial",
  mt: 1.5,
  backgroundImage: "none",
  backgroundColor: "initial",
  color: (theme) => theme.palette.text.primary
};

const fullScreenNavSX = {
  position: "fixed",
  paddingTop: "58px",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  zIndex: -1,
  width: "100vw",
  height: "100vh",
  left: 0,
  top: 0,
  minHeight: "100vh",
  background: "#000"
};

const buttonSX = {
  padding: "5px",
  width: "65px",
  height: "26px",
  display: "inline-flex",
  "--aug-border-bg": (theme) => theme.palette.text.primary,

  "& > p": {
    fontSize: "12px"
  },

  "& > span": {
    marginRight: "0"
  }
};

const MobileNavbar = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const triedEagerLogin = useEagerConnect();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [accountPopoverOpen, setAccountPopoverOpen] = useState(false);
  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const { active } = useWeb3React();
  const [sublistOpen, setSublistOpen] = useState(-1);
  const [anchorElWal, setAnchorElWal] = useState(null);
  const [isWalletPopoverOpen, setIsWalletPopoverOpen] = useState(false);

  useInactiveListener(!triedEagerLogin);

  function handleOpenSublist(index) {
    setSublistOpen(sublistOpen === -1 ? index : -1);
  }

  function handleWalletPopover(event, status) {
    if (status) setAnchorElWal(status ? event.currentTarget : null);
    setIsWalletPopoverOpen(status);
  }
  function handleAccountPopover(event, status) {
    if (status) setAnchorElAccount(status ? event.currentTarget : null);
    setAccountPopoverOpen(status);
  }

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: (theme) => theme.zIndex.modal - 1,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "space-between",
        backgroundColor: "#000"
      }}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#000"
        }}>
        <AppBar sx={appBarSX} position="static">
          <Container sx={{ ml: "0", p: 0 }} maxWidth="xl">
            <Toolbar style={{ height: "40px", minHeight: "0px" }} disableGutters>
              <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                <BurgerToggleIcon onChange={() => setIsOpen(!isOpen)} open={isOpen} />

                <Box
                  component="img"
                  onClick={() => window.open("https://delysium.com/", "_blank")}
                  src={CDN_ASSETS.LOGO}
                  sx={{
                    cursor: "pointer",
                    mr: "6px",
                    ml: "12px",
                    maxHeight: "42px",
                    width: "auto",
                    display: "flex"
                  }}
                />

                <AnimatePresence>
                  {isOpen && (
                    <Box component={motion.ul} {...varFadeIn} sx={fullScreenNavSX}>
                      {PAGES.map(({ title, url = "#", sublists }, index) =>
                        sublists ? (
                          <>
                            <ClickAwayListener
                              onClickAway={() => {
                                setIsOpen(false);
                                handleOpenSublist(-1);
                              }}>
                              <Box
                                onClick={() => handleOpenSublist(index)}
                                className={clsx(classes.navLink)}>
                                {title}
                              </Box>
                            </ClickAwayListener>
                            {sublistOpen === index && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexFlow: "row nowrap",
                                  columnGap: 8.5,
                                  ml: 10.125,
                                  my: 2,
                                  mb: 1.5
                                }}>
                                {Object.entries(sublists).map(([listTitle, items]) => {
                                  return (
                                    <Box sx={{ display: "flex", flexFlow: "column nowrap" }}>
                                      <Typography variant="body2" color={alpha("#FFF", 0.6)}>
                                        {listTitle}
                                      </Typography>
                                      {items.map((item, i) => (
                                        <Box
                                          component="a"
                                          key={i}
                                          onClick={() => ReactGA.event(`enter_${item.title}`)}
                                          href={item.url}
                                          sx={{ textDecoration: "none" }}>
                                          <Typography
                                            variant="body2"
                                            color="textPrimary"
                                            sx={{
                                              display: "flex",
                                              opacity: 0.8,
                                              textAlign: "left",
                                              fontWeight: 600,
                                              my: 0.75
                                            }}>
                                            {item.title}
                                          </Typography>
                                        </Box>
                                      ))}
                                    </Box>
                                  );
                                })}
                              </Box>
                            )}
                          </>
                        ) : (
                          <NavLink
                            exact
                            to={url}
                            key={index}
                            className={classes.navLink}
                            activeClassName={classes.activeNavLink}>
                            {title}
                          </NavLink>
                        )
                      )}
                    </Box>
                  )}
                </AnimatePresence>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

        <Box
          sx={{
            flexGrow: 0,
            zIndex: (theme) => theme.zIndex.modal + 1,
            pr: { xs: 2.5 },
            mt: "18px",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-evenly"
          }}>
          {active && (
            <IconButton
              disableRipple
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ mr: 1 }}
              data-augmented-ui="tl-clip br-clip border"
              className={clsx(classes.iconButton, isWalletPopoverOpen && classes.iconButtonColored)}
              onClick={(e) => {
                e.preventDefault();
                handleWalletPopover(e, true);
              }}>
              {isWalletPopoverOpen ? <WalletIcon /> : <WalletWhiteIcon />}
            </IconButton>
          )}

          {isAuthenticated ? (
            <IconButton
              disableRipple
              variant="outlined"
              color="inherit"
              size="small"
              data-augmented-ui="tl-clip br-clip border"
              className={clsx(classes.iconButton, accountPopoverOpen && classes.iconButtonColored)}
              onClick={(e) => {
                e.preventDefault();
                handleAccountPopover(e, true);
              }}>
              {accountPopoverOpen ? <UserIcon /> : <UserWhiteIcon />}
            </IconButton>
          ) : (
            <Button
              variant="outlined"
              color="inherit"
              sx={buttonSX}
              data-augmented-ui="tl-clip br-clip border"
              className={classes.connectButton}
              disableRipple
              onClick={() => {
                ReactGA.event(`enter_dashboard`);
                window.location.assign(PATH_EXTERNAL.sso.login);
              }}>
              <Typography color={"textPrimary"}>SIGN IN</Typography>
            </Button>
          )}

          <AccountPopover
            isOpen={accountPopoverOpen}
            anchorEl={anchorElAccount}
            handleClose={() => handleAccountPopover(null, false)}
          />

          <WalletPopover
            isWalletOpen={isWalletPopoverOpen}
            anchorElWal={anchorElWal}
            handleClose={() => handleWalletPopover(null, false)}
          />
        </Box>
      </Box>

    </Box>
  );
};
export default MobileNavbar;
